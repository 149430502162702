import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#F8F8F8" />
    <rect x="1.5" y="1.5" width="17" height="17" rx="8.5" fill="white" />
    <rect x="1.5" y="1.5" width="17" height="17" rx="8.5" stroke="white" />
    <path d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10ZM5.8 10C5.8 12.3196 7.6804 14.2 10 14.2C12.3196 14.2 14.2 12.3196 14.2 10C14.2 7.6804 12.3196 5.8 10 5.8C7.6804 5.8 5.8 7.6804 5.8 10Z" fill="#EEEEEE" />
    <path d="M10 4.4C10 3.6268 10.6329 2.98592 11.3907 3.13954C12.2789 3.31959 13.1281 3.67131 13.889 4.17971C15.0401 4.94888 15.9373 6.04213 16.4672 7.32122C16.997 8.6003 17.1356 10.0078 16.8655 11.3656C16.5954 12.7235 15.9287 13.9708 14.9497 14.9497C13.9708 15.9287 12.7235 16.5954 11.3656 16.8655C10.0078 17.1356 8.6003 16.997 7.32122 16.4672C6.04213 15.9373 4.94888 15.0401 4.17971 13.889C3.67131 13.1281 3.31959 12.2789 3.13954 11.3907C2.98592 10.6329 3.6268 10 4.4 10C5.1732 10 5.7782 10.6436 6.03119 11.3742C6.14786 11.7112 6.30759 12.0337 6.50783 12.3334C6.96933 13.0241 7.62528 13.5624 8.39273 13.8803C9.16018 14.1982 10.0047 14.2814 10.8194 14.1193C11.6341 13.9572 12.3825 13.5572 12.9698 12.9698C13.5572 12.3825 13.9572 11.6341 14.1193 10.8194C14.2814 10.0047 14.1982 9.16018 13.8803 8.39273C13.5624 7.62528 13.0241 6.96933 12.3334 6.50783C12.0337 6.30759 11.7112 6.14786 11.3742 6.03119C10.6436 5.7782 10 5.1732 10 4.4Z" fill="#FF7A7A" />
  </svg>
);
