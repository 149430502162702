export const NO_ACCESS = 0;
export const READ_ACCESS = 10;
export const EDIT_ACCESS = 20;
export const CREATE_ACCESS = 30;
export const DELETE_ACCESS = 40;

const roleNames = {
  [NO_ACCESS]: ['none'],
  [READ_ACCESS]: ['read'],
  [EDIT_ACCESS]: ['read', 'edit'],
  [CREATE_ACCESS]: ['read', 'edit', 'create'],
  [DELETE_ACCESS]: ['read', 'edit', 'create', 'delete'],
};

export const getPermission = (name, roles) => (roles && roleNames[roles[name]]) || ['none'];

export const isPermitted = (item, detailRole, ws) => {
  if (!(detailRole && detailRole.role)) {
    return false;
  }

  const profiles = getPermission('permissionProfiles', detailRole.role);
  const billing = getPermission('permissionBilling', detailRole.role);
  const users = getPermission('permissionUsers', detailRole.role);
  const deviceOS = getPermission('permissionOSUpdate', detailRole.role);
  const devices = getPermission('permissionDevices', detailRole.role);
  const applications = getPermission('permissionApplication', detailRole.role);
  const modules = getPermission('permissionModules', detailRole.role);
  const roles = getPermission('permissionRoles', detailRole.role);
  const workspace = getPermission('permissionWorkspace', detailRole.role);
  const terminalAccess = getPermission('permissionTerminalAccessManage', detailRole.role)
  const model = getPermission('permissionAIModel', detailRole.role);
  const dataset = ws && ws.datasetEnabled && getPermission('permissionDataset', detailRole.role);
  const video = getPermission('permissionDataCollection', detailRole.role);
  const imageAnnotation = getPermission('permissionImageAnnotation', detailRole.role);
  const modelTraining = getPermission('permissionModelTraining', detailRole.role);
  const terminal = getPermission('permissionTerminalAccessManage', detailRole.role);
  const videoViewer = getPermission('permissionVideoViewerManage', detailRole.role)
  const library = applications.concat(modules);
  const firstBlock = video.concat(imageAnnotation).concat(modelTraining).concat(model);
  const deployment = profiles.concat(devices);
  const audit = getPermission('permissionWorkspaceHistory', detailRole.role);
  const incidents = ws && ws.incidentsEnabled && ['read'];
  const dashboards = ws && ws.dashboardsEnabled && ['read'];

  const permissionDetail = {
    profiles,
    deployment,
    library,
    devices,
    dataset,
    deviceOS,
    'devices-enrollment': devices,
    'device-configuration': devices,
    users,
    profile: ['read'],
    myWorkspaces: ['read'],
    applications,
    permissionWorkspace: workspace,
    imageAnnotation,
    modelTraining,
    terminal,
    videoViewer: videoViewer,
    modules,
    videos: video,
    models: model,
    role: roles,
    terminalAccess: terminalAccess,
    authentication: workspace,
    workspace,
    // dashboard: getPermission('permissionDashboard', detailRole.role),
    // dashboards: getPermission('permissionDashboard', detailRole.role),
    settings: ['read'],
    billing,
    audit,
    incidents,
    dashboards,
    planLimitsUsage: billing,
    marketplace: ['read'],
    partner: billing,
    line: ['read'],
    back: ['read'],
    'first-block': firstBlock,
    'second-block': library,
    'third-block': deployment,
  };

  const permissions = permissionDetail[item.id] || ['none'];

  if (item.id === 'application-edit' && permissionDetail.applications.includes('create')) {
    return true;
  }

  if (permissions.includes('read')) {
    return true;
  }

  return false;
};
