import { preInstallableSolutions } from 'api/marketplace';
import { devPlan } from 'api/plans';
import { createWorkspace, getWorkspacePending } from 'api/workspace';
import AHref from 'components/AHref';
import LoadingIndicator from 'components/LoadingIndicator';
import ProcessPage from 'components/ProcessPage';
import { makeSelectUser } from 'containers/App/selectors';
import ConfirmOrder from 'containers/Billing/components/details/ConfirmOrder';
import PaymentSettings from 'containers/Billing/components/details/PaymentSettings';
import LanguageProvider from 'containers/LanguageProvider';
import { intl } from 'containers/LanguageProvider/intlProvider';
import {
  registerRequest,
  selectWorkspaceRequest
} from 'containers/Login/actions';
import reducer from 'containers/Login/reducer';
import saga from 'containers/Login/saga';
import {
  makeLoggedInEmail, makeSelectLoading
} from 'containers/Login/selectors';
import Plan from 'containers/Plans/PlanSelector';
import { makeSelectMe } from 'containers/Settings/User/selectors';
import { createWorkspaceRequest } from 'containers/Settings/Workspace/actions';
import { makeSelectFetchWorkspace } from 'containers/Settings/Workspace/selectors';
import { defaultNotifier } from 'functions/notificationHandler';
import { translationMessages } from 'i18n';
import { isEmpty, isNil, omitBy } from 'lodash';
import postalCodes from 'postal-codes-js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { event } from 'react-ga4';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {withRouter } from 'withRouter'
import slugify from 'react-slugify';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { isFreePrice, isValidCode } from 'utils/common';
import { EXTERNAL_URL } from 'utils/constants';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from 'utils/messages';
import { routeMapping } from '../../../api/constants';
import ConfirmationPage from './ConfirmationPage';
import { CONFIRMATION_PAGE, REGISTER_PAGE, WORKSPACE_PAGE } from './constants';
import RegisterForm from './Form';
import PaymentInfo from './PaymentInfo';
import PreApplicationSelection from './PreApplicationSelection';
import WorkspaceForm from './WorkspaceForm';
import WorkspaceForm2 from './WorkspaceForm2';

class Register extends Component {
  constructor() {
    super();
    this.recaptchaRef = React.createRef();
  }

  state = {
    endpoint: '' || 0,
    isLoading: false,
    workspaceName: '',
    subscription: {},
    planId: null,
    interval: 'year',
    currentPage: REGISTER_PAGE,
    showBillingPage: false,
    showPaymentPage: false,
    showConfirmPage: false,
    showPlanSelectPage: false,
    showWsPage: true,
    firstName: '',
    lastName: '',
    country: '',
    address1: '',
    address2: '',
    city: '',
    postCode: '',
    company: '',
    vat: '',
    applications: [],
    userExperiences1: [],
    userExperiences2: [],
    activeSelection: ''
  };

  onSelectTag = (id, name) => {
    if (this.state[name].includes(id)) {
      return this.setState({[name]: this.state[name].filter(item => item !== id)})
    }

    this.setState({ [name]: this.state[name].concat(id) });
  };

  onSelectApp = id => {
    if (this.state.activeSelection === id) {
      return this.setState({activeSelection: ''})
    }

    this.setState({ activeSelection: id });
  };

  onSkipApp = () => {
    this.setState({ activeSelection: '' });
    this.onSubmitSubscription();
  };

  onChangePayment = (field, value) => {
    this.setState({ [field]: value });
  };

  async componentDidMount() {
    const { isCreateWorkspace, isCreatePage, user } = this.props;

    if (this.props.router.location.pathname === '/register') {
      return this.setState({showWsPage: false})
    }

    if (!isCreateWorkspace) {
      this.setState({ showPlanSelectPage: false });
    }

    if (isCreateWorkspace) {
      this.setState({ showWsPage: true });
      this.setState({isLoading: true})
      try {
        const {data} = await getWorkspacePending();
        if (data.errors) {
          this.setState({isLoading: false})
          return false;
        }

        this.setState({
          currentWsName: data.data.pendingWorkspace.name,
          currentOrganizationName: data.data.pendingWorkspace.organizationName,
          isLoading: false
        })
      } catch (err) {
        this.setState({isLoading: false})
      }

    }

    if (isCreatePage) {
      // const { data } = await devPlan();

      // if (data.errors) {
      //   return defaultNotifier(data.errors);
      // }

      this.setState({
        currentPage: WORKSPACE_PAGE,
        showWsPage: true,
        showPlanSelectPage: false,
        isCreatePage: true,
        planId: '',
        subscription: ''
      });
    }

    const {data} = await preInstallableSolutions();

    if (data.errors) {
      return false
    }

    this.setState({applications: data.data.preInstallableSolutions})
  }

  onUpdatePage = page => {
    this.setState({ currentPage: page });
  };

  onClose = () => {
    const { workspace, userData = {} } = this.props;
    const { reset } = this.state;

    if (reset) {
      reset();
    }

    if (userData && userData.id) {
      return this.props.router.navigate(-1);
    }

    if (workspace && workspace.billingContactId) {
      return this.props.router.navigate(
        `/${workspace && slugify(workspace.name)}/settings/workspace/manage`
      );
    }

    return this.props.router.navigate(routeMapping.LOGIN.path);
  };

  onValidWorkspace = async (data, reset) => {
    // if (!this.state.endpoint) {
    //   defaultNotifier(`${intl.formatMessage({...messages.planMustBeSelected})}`)
    // }

    this.setState({ workspace: data, reset }, () => {
      this.onNextWorkspace();
    });
  };

  onNextWorkspace = () => {
    this.onSubmitSubscription()
    // this.setState({ showWsPage: false, showPreSelectPage: true });
  };

  onSubmitTag = () => {
    this.setState({ showTagSelectPage: false, showPreSelectPage: true });
  };

  onSubmitApplication = () => {
    const peopleDetection = this.state.applications.find(item => item.name === 'Person Detection');

    this.setState({activeSelection: peopleDetection.id}, () => this.onSubmitSubscription());
  };

  onRegister = async data => {
    const token = await this.recaptchaRef.current.executeAsync();

    const names = data.fullname.split(' ');
    const firstName = names[0];
    const lastName = names
      .filter((name, index) => index !== 0)
      .reduce((total, name) => `${total} ${name}`);

    const user = {
      firstName: firstName || '',
      lastName: lastName || '',
      email: data.email,
      password: data.password,
      captchaToken: token
    };

    await this.props.registerRequest(user, () => {
      this.setState({ user, currentPage: CONFIRMATION_PAGE });
      event({
        action: 'User created',
        category: 'User created'
      });
    });
  };

  componentWillUnmount() {
    localStorage.setItem('subDomainName', '');
    localStorage.setItem('newAccessToken', '');
  }

  onSubmitSubscription = async () => {
    const {user} = this.props;
    const {
      workspace,
      planId,
      paymentMethodId,
      subscription,
      firstName,
      lastName,
      address1,
      address2,
      city,
      postCode,
      company,
      vat
    } = this.state;
    // const { isCreatePage } = this.props;

    // let trialPeriods = subscription.ype === 'TRIAL' && (subscription.ype === 'TRIAL').duration.number;

    // const trialFee =
    //   subscription.trialFee;

    // const isFreeTrial = trialPeriods && !trialFee;

    // const intervalPeriod = {
    //   month: 'monthlyPrice',
    //   year: 'yearlyPrice'
    // };


    // const isValid = () => {
    //   if (!isFreeTrial && !isCreatePage) {
    //     return (paymentMethodId &&
    //       firstName &&
    //       lastName
    //     )
    //   }

    //   // if (ignoreCreditCard) {
    //   //   return true
    //   // }

    //   return paymentMethodId
    // }

    // if (isValid()) {
    const payload = {
      name: workspace.name,
      organizationName: workspace.organizationName || '',
      // organizationAddress: workspace.organizationAddress || '',
      organizationIndustry: workspace.organizationIndustry || '',
      organizationSize: workspace.organizationSize || '',
      // planId: '',
      // paymentMethodId: paymentMethodId,
      // userExperiences1: this.state.userExperiences1,
      // userExperiences2: this.state.userExperiences2,
      preInstallAppId: this.state.activeSelection,
      billingInfo: {
        firstName: '',
        lastName: '',
        country: workspace.country,
        address1,
        address2,
        city,
        postCode: postCode || '',
        company: company || '',
        vat: vat || ''
      }
    };

    const query = omitBy(payload, isNil, isEmpty);

    try {
      this.setState({ isLoading: true });
      const { data } = await createWorkspace(query);

      if (data.errors) {
        this.setState({ isLoading: false });
        this.setState({ firstName: '', lastName: '', paymentMethodId: '' });

        return defaultNotifier(data.errors);
      }
      const ws = data.data.createWorkspace.name;
      const { reset } = this.state;

      event({
        action: 'Ws created',
        category: 'Ws created'
      });

      if (reset) {
        reset();
      }
      await this.props.selectWorkspaceRequest(
        { subDomain: data.data.createWorkspace.subDomain },
        {
          redirectTo: `/${slugify(ws)}?getStarted=true`,
          onCallBack: () => this.setState({ isLoading: false }),
          push: this.props.router.navigate
        }
      );
      this.setState({ isLoading: false });
    } catch (err) {
      this.setState({ firstName: '', lastName: '' });
      this.setState({ isLoading: false });
      defaultNotifier(
        `${intl.formatMessage({ ...messages.errorWhileSubmittingBilling })}`
      );
    }
    // }
  };

  onSubmitWithoutCard = async () => {
    const {
      workspace,
      planId,
      subscription,
      interval,
      firstName,
      lastName    } = this.state;

    let trialPeriods = subscription.ype === 'TRIAL' && (subscription.ype === 'TRIAL').duration.number;

    const trialFee =
      subscription.trialFee;

    const isFreeTrial = trialPeriods && !trialFee;

    const intervalPeriod = {
      month: 'monthlyPrice',
      year: 'yearlyPrice'
    };

    const payload = {
      name: workspace.name,
      organizationName: workspace.organizationName || '',
      organizationAddress: workspace.organizationAddress || '',
      organizationIndustry: workspace.organizationIndustry || '',
      organizationSize: workspace.organizationSize || '',
      planId: planId,
      userExperiences1: this.state.userExperiences1,
      userExperiences2: this.state.userExperiences2,
      preInstallAppId: this.state.activeSelection,
      billingInfo: {
        firstName: isFreeTrial ? '' : firstName,
        lastName: isFreeTrial ? '' : lastName,
        country: workspace.country,
      }
    };

    const query = omitBy(payload, isNil, isEmpty);

    try {
      this.setState({ isLoading: true });
      const { data } = await createWorkspace(query);

      if (data.errors) {
        this.setState({ isLoading: false });
        this.setState({ firstName: '', lastName: '', paymentMethodId: '' });

        return defaultNotifier(data.errors);
      }
      const ws = data.data.createWorkspace.name;
      const { reset } = this.state;

      event({
        action: 'Ws created',
        category: 'Ws created'
      });

      if (reset) {
        reset();
      }
      await this.props.selectWorkspaceRequest(
        { subDomain: data.data.createWorkspace.subDomain },
        {
          redirectTo: `/${slugify(ws)}?getStarted=true`,
          onCallBack: () => this.setState({ isLoading: false }),
          push: this.props.router.navigate
        }
      );
      this.setState({ isLoading: false });
    } catch (err) {
      this.setState({ firstName: '', lastName: '' });
      this.setState({ isLoading: false });
      defaultNotifier(
        `${intl.formatMessage({ ...messages.errorWhileSubmittingBilling })}`
      );
    }
  }

  isValidCode = () => {
    const { country, postCode } = this.state;

    if (!postCode) {
      return 'Zip code is required.';
    }

    const message = postalCodes.validate(country, postCode);
    if (typeof message === 'boolean') {
      return '';
    }

    if (message) {
      return 'Invalid zip code';
    }
  };

  onSelectPlan = (data, endpoint, price, interval) => {

    this.setState({
      showPlanSelectPage: false,
      showBillingPage: true,
      planId: data.id,
      subscription: data,
      endpoint: endpoint || 0,
      price: price,
      interval
    });
  };

  onSubmitPayment = (data, name) => {
    this.setState({
      showPaymentPage: false,
      showConfirmPage: true,
      paymentMethodId: data.paymentMethod.id,
      interval: this.state.interval,
      card: data.paymentMethod.card,
      name
    });
  };

  navigateToPaymentSetting = () => {
    this.setState({ showPaymentPage: true, showConfirmPage: false });
  };

  onUpdateInterval = (id, interval) => {
    this.setState({ priceId: id, interval });
  };

  onGetPaymentInfo = data => {
    if (data.paymentMethod) {
      return this.setState({ paymentMethodId: data.paymentMethod.id });
    }

    return this.setState({ paymentMethodId: '' });
  };

  render() {
    const {
      endpoint,
      price,
      name,
      currentPage,
      showPlanSelectPage,
      showPaymentPage,
      showConfirmPage,
      showBillingPage,
      subscription,
      workspace = {},
      card,
      workspaceName,
      paymentMethodId,
      firstName,
      lastName,
      country,
      interval,
      postCode,
      showWsPage,
      showPreSelectPage,
      showTagSelectPage    } = this.state;
    const { user, userData, loggedInEmail, isCreatePage } = this.props;

    const isValidZipcode = isValidCode(country, postCode);
    const query = new URLSearchParams(this.props.router.location.search);
    const email = query.get('email');
    let trialPeriods = '';
    let trialFee = '';

    if (
      subscription &&
      (subscription['yearlyPrice'] || subscription['monthlyPrice'])
    ) {
      trialPeriods = subscription.ype === 'TRIAL' && (subscription.ype === 'TRIAL').duration.number;


      trialFee =
        subscription.trialFee;
    }

    const ignoreCreditCard = subscription.id && isFreePrice(subscription, interval);

    const isFreeTrial = trialPeriods && !trialFee;
    const isValid = () => {
      if (!isFreeTrial && !isCreatePage) {
        return (paymentMethodId &&
          firstName &&
          lastName
        )
      }

      // if (ignoreCreditCard) {
      //   return true
      // }

      return paymentMethodId
    }

    const isDisabled =
      !(isValid());

    if (this.state.isLoading) {
      return <LoadingIndicator isProcessPage />;
    }

    if (showConfirmPage) {
      return (
        <LanguageProvider messages={translationMessages}>
          <ConfirmOrder
            onClose={this.onClose}
            plan={subscription}
            price={price}
            name={name}
            card={card}
            interval={this.state.interval}
            workspace={this.props.workspace}
            workspaceName={workspaceName}
            endpoint={endpoint}
            isLoading={this.state.isLoading}
            data={subscription}
            onBack={this.navigateToPaymentSetting}
            onSubmit={this.onSubmitSubscription}
          />
        </LanguageProvider>
      );
    }

    if (showPaymentPage) {
      return (
        <LanguageProvider messages={translationMessages}>
          <PaymentSettings
            isLoading={this.state.isLoading}
            isCreatePage={this.props.isCreatePage}
            price={price}
            workspaceName={
              workspace.name ||
              (this.props.workspace && this.props.workspace.subDomain)
            }
            initialValues={{
              interval: this.state.interval
            }}
            onSubmit={this.onSubmitPayment}
            onClose={this.onClose}
          />
        </LanguageProvider>
      );
    }

    if (currentPage === CONFIRMATION_PAGE) {
      return (
        <LanguageProvider messages={translationMessages}>
          <ConfirmationPage
            interval={this.state.interval}
            workspaceName={
              workspace.name ||
              (this.props.workspace && this.props.workspace.subDomain)
            }
            onClose={this.onClose}
          />
        </LanguageProvider>
      );
    }

    if (showPlanSelectPage) {
      return (
        <LanguageProvider messages={translationMessages}>
          <Plan onClose={this.onClose} onSelectPlan={this.onSelectPlan} />
        </LanguageProvider>
      );
    }

    if (showWsPage) {
      return (
        <LanguageProvider messages={translationMessages}>
          <ProcessPage customClass={'register-page'} onCancel={this.onClose}>
            {() => (
              <div>
                <div className="headline">
                  <>
                    <h1 className="headline__title center headline_title__register">
                      <FormattedMessage {...messages.setUpNewWs} />
                    </h1>
                    <h3 className="headline__subtitle">
                      Welcome {user && user.email}
                    </h3>
                  </>
                </div>
                <div className="login">
                  <WorkspaceForm
                    onSubmit={this.onValidWorkspace}
                    isLoading={this.state.isLoading}
                    initialValues={{
                      name: this.state.currentWsName,
                      organizationName: this.state.currentOrganizationName,
                      organizationSize: '',
                      organizationIndustry: '',
                      creatorPosition: ''
                    }}
                  />
                </div>
              </div>
            )}
          </ProcessPage>
        </LanguageProvider>
      );
    }

    if (showTagSelectPage) {
      return (
        <LanguageProvider messages={translationMessages}>
          <ProcessPage customClass={'register-page'} onCancel={this.onClose}>
            {() => (
              <div>
                <div className="headline">
                  <>
                    <h1 className="headline__title center headline_title__register">
                      Let us customize your experience
                    </h1>
                    <h3 className="headline__subtitle">
                      Please tell us how you plan to use Viso Suite.
                    </h3>
                  </>
                </div>
                <div className="login">
                  <WorkspaceForm2
                    onSelectTag={this.onSelectTag}
                    userExperiences1={this.state.userExperiences1}
                    userExperiences2={this.state.userExperiences2}
                    onSubmit={this.onSubmitTag}
                  />
                </div>
                <div className='progress-bar progress-bar_accent0'>
                  <div style={{ width: '66.66%' }} />
                </div>
              </div>
            )}
          </ProcessPage>
        </LanguageProvider>
      );
    }

    if (showPreSelectPage) {
      return (
        <LanguageProvider messages={translationMessages}>
          <ProcessPage customClass={'register-page'} onCancel={this.onClose}>
            {() => (
              <div>
                <div className="headline">
                  <>
                    <h1 className="headline__title center headline_title__register">
                    Get started fast
                    </h1>
                    <h3 className="headline__subtitle">
                    Get a head start and pre-install the components for your first application.
                    </h3>
                  </>
                </div>
                <div className="login">
                  <PreApplicationSelection
                    applications={this.state.applications}
                    onSubmit={this.onSubmitApplication}
                    onSkip={this.onSkipApp}
                    onSelect={this.onSelectApp}
                  />
                </div>
              </div>
            )}
          </ProcessPage>
        </LanguageProvider>
      );
    }

    if (showBillingPage) {
      return (
        <LanguageProvider messages={translationMessages}>
          <PaymentInfo
            {...this.state}
            isFreePrice={ignoreCreditCard}
            onUpdateInterval={this.onUpdateInterval}
            interval={this.state.interval}
            isDisabled={isDisabled}
            isCreatePage={this.props.isCreatePage}
            selectedCountry={workspace.country}
            organizationSize={workspace.organizationSize}
            isValidZipcode={isValidZipcode}
            onChangePayment={this.onChangePayment}
            onGetPaymentInfo={this.onGetPaymentInfo}
            onBack={() => {
              if (this.props.isCreatePage) {
                return this.setState({
                  showPlanSelectPage: false,
                  showBillingPage: false,
                  showWsPage: true
                });
              }

              this.setState({
                showPlanSelectPage: true,
                showBillingPage: false
              });
            }}
            subscription={this.state.subscription}
            isLoading={this.state.isLoading}
            workspaceName={
              workspace.name ||
              (this.props.workspace && this.props.workspace.subDomain)
            }
            workspace={this.props.workspace}
            onClose={this.onClose}
            onSubmit={this.onSubmitSubscription}
            onSubmitWithoutCard={this.onSubmitWithoutCard}
          />
        </LanguageProvider>
      );
    }

    return (
      <LanguageProvider messages={translationMessages}>
        <ProcessPage
          customClass={currentPage === WORKSPACE_PAGE ? 'register-page' : ''}
          onCancel={currentPage !== REGISTER_PAGE && (() => this.onClose())}
        >
          {() => (
            <div>
              <div className='form-layout'>
                <div className="headline">
                  {currentPage === REGISTER_PAGE && (
                  <>
                    <h1 className="headline__title center headline_title__register">
                      <FormattedMessage {...messages.signUpViso} />
                    </h1>
                    <div className="headline__subtitle h3">
                      <FormattedMessage
                        {...messages.createYourPersonalAccount}
                      />
                    </div>
                  </>
                  )}
                  {currentPage === WORKSPACE_PAGE && (
                  <>
                    <h1 className="headline__title center headline_title__register">
                      <FormattedMessage {...messages.setUpNewWs} />
                    </h1>
                    <h3 className="headline__subtitle">
                      <FormattedMessage {...messages.welcome} />{' '}
                      {loggedInEmail ||
                        (user && user.email) ||
                        (userData && userData.email)}
                    </h3>
                  </>
                  )}
                </div>
                <div className="login">
                  {currentPage === REGISTER_PAGE && (
                  <>
                    <RegisterForm
                      autoFocus={email}
                      onSubmit={this.onRegister}
                      initialValues={{
                        email: email
                      }}
                      isLoading={this.props.isLoading}
                    />
                    <ReCAPTCHA
                      ref={this.recaptchaRef}
                      size="invisible"
                      sitekey={process.env.RECAPTCHA_SITE_KEY}
                      onChange={this.onChange}
                    />
                  </>
                  )}
                  {currentPage === WORKSPACE_PAGE && (
                    <PreApplicationSelection />
                  )}
                </div>
              </div>
              {currentPage === REGISTER_PAGE &&
              <div className="current-version">
                <div className="h3">© viso.ai ·{' '}
                  <AHref href={EXTERNAL_URL.terms} className="text-link" rel='noreferrer' target='_blank'>Privacy & terms</AHref>
                </div>
              </div>
              }
            </div>
          )}
        </ProcessPage>
      </LanguageProvider>
    );
  }
}

Register.propTypes = {
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  isCreateWorkspace: PropTypes.bool,
  createWorkspaceRequest: PropTypes.func,
  registerRequest: PropTypes.func
};


const mapStateToProps = createStructuredSelector({
  user: makeSelectMe(),
  loggedInEmail: makeLoggedInEmail(),
  userData: makeSelectUser(),
  isLoading: makeSelectLoading(),
  workspace: makeSelectFetchWorkspace()
});

const withConnect = connect(
  mapStateToProps,
  { registerRequest, createWorkspaceRequest, selectWorkspaceRequest }
);

const withReducer = injectReducer({ key: 'login', reducer });
const withSaga = injectSaga({ key: 'login', saga });

export default compose(
  withReducer,
  withSaga,
  withRouter,
  withConnect
)(Register);
