// Needed for redux-saga es6 generator support
import "@babel/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import history from "utils/history";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

import ReactGA from 'react-ga4';

import "react-toastify/dist/ReactToastify.css";
import "url-search-params-polyfill";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import 'react-circular-progressbar/dist/styles.css';

import AppContainer from "./AppContainer";

// Load the favicon and the .htaccess file
import "!file-loader?name=[name].[ext]!./images/favicon.jpg";
import "file-loader?name=.htaccess!./.htaccess";

import configureStore from "./configureStore";
import ErrorBoundary from "ErrorBoundary";

import "styles/styles.scss";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/v5-font-face.css";

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById("app");


Sentry.init({ 
  beforeSend(event, hint) {
    if (hint.originalException === "Timeout") return null;
    if(event.name === "SecurityError") return null;
    // if (event.exception) {
    //   Sentry.showReportDialog({ eventId: event.event_id });
    // }
    return event;
  },
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  tracesSampleRate: 1.
});


ReactGA.initialize('G-6YPCL2SK6L');  


ReactDOM.render(
  <Provider store={store}>
      <ErrorBoundary>
          <AppContainer />
      </ErrorBoundary>
  </Provider>,
  MOUNT_NODE
);
