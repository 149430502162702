import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars';
import classNames from 'classnames';

import messages from 'utils/messages';
import { snakeCaseToTitleCase } from 'utils/common';
import { FILTER_TYPE, ALL } from '../constants';

class Radio extends React.Component {
  state = {
    filterValue: '',
    selectedId: '',
  }

  onApply = () => {
    const { filterValue, selectedId } = this.state;
    const { name, onFilterApply } = this.props;

    onFilterApply(name, filterValue, FILTER_TYPE.RADIO, selectedId);
  }

  componentDidMount() {
    const { value } = this.props;

    this.setState({ filterValue: value });
    document.addEventListener('keydown', this.onEnter, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnter, false);
  }

  onChange = (value) => {
    const { columns } = this.props;
    this.setState({ filterValue: value });

    this.setState({
      selectedId:
        columns.find(column => column.name === value) ? [columns.find(column => column.name === value).id] : null,
    });
  }

  render() {
    const { filterValue } = this.state;
    const { title, columns, customClass } = this.props;
    const radioClass = `radio radio_blocked radio_${customClass}`;

    const btnClass = classNames('filters-bar__apply', {
      [customClass]: customClass,
      'filters-bar__disabled': !this.state.filterValue,
    });

    return (
      <>
        <div className="filters-bar__heading">{title}</div>
        <Scrollbars
          autoHeight
          autoHide
          autoHideDuration={1}
          autoHeightMax={columns.length > 0 ? 250 : 0}
          renderView={props => <div {...props} className="view" />}
        >
          <div className={radioClass}>
            <input type="radio" name="radio" className="radio__input" onClick={() => this.onChange(ALL)} checked={filterValue === 'All'} readOnly />
            <div className="radio__check" />
            <label className="radio__label">
              <FormattedMessage {...messages.all} />
            </label>
          </div>
          {columns
            .filter(column => column.label)
            .map(column => {
              return (
              <div className={radioClass} key={column.label}>
                <input type="radio" name="radio" className="radio__input" onClick={() => this.onChange(column.label)} checked={filterValue === column.label} readOnly />
                <div className="radio__check" />
                  <label className="radio__label grid-col" title={column?.label}>{column?.label && snakeCaseToTitleCase(column.label.toString())}</label>
              </div>
            )})}
        </Scrollbars>
        <button type="button" className={btnClass} onClick={this.onApply}>
          <FormattedMessage {...messages.apply} />
        </button>
      </>
    );
  }
}

Radio.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  columns: PropTypes.array,
  customClass: PropTypes.string,
  onFilterApply: PropTypes.func,
};

export default Radio;
